@property --_l {
  syntax: "<length>";
  initial-value: 0px;
  inherits: true;
}
@property --_r {
  syntax: "<length>";
  initial-value: 0px;
  inherits: true;
}

.tile {
  --d: 3px; /* the depth */
  --a: 20deg; /* the angle */

  /* the below value is based on the depth and the angle.
       the formula is a bit difficult to express so we update it manually
    */
  --x: 10px;
  transform: rotate(90deg);
  aspect-ratio: 1.1; /* you can use 1 as ratio but I found 1.1 a little better */
  padding-block: var(--d);
  transform: perspective(400px) rotateY(calc(var(--_i, 1) * var(--a)));
  --_d: calc(100% - var(--d));
  --_l: 0px;
  --_r: 0px;
  clip-path: polygon(
    var(--_l) calc(var(--_d) - var(--x)),
    var(--_l) calc(var(--d) + var(--x)),
    var(--d) var(--d),
    var(--_d) var(--d),
    calc(var(--_d) + var(--_r)) calc(var(--d) + var(--x)),
    calc(var(--_d) + var(--_r)) calc(var(--_d) - var(--x)),
    var(--_d) var(--_d),
    var(--d) var(--_d)
  );
  transition: -webkit-mask 0.4s, transform 0.5s, --_r 0.3s, --_l 0.25s 0.25s;
  transition-timing-function: linear;
  cursor: pointer;
  outline: var(--d) solid #0008;
  outline-offset: calc(-1 * var(--d));
}

.glossy {
  mask: linear-gradient(90deg, #000 35%, #000c, #000 65%) 100% 0%/250% 250%;
  -webkit-mask: linear-gradient(90deg, #000 35%, #000c, #000 65%) 100% 0%/250% 250%;
}

.glossy:hover,
.glossy.alt {
  mask-position: 0 0;
  -webkit-mask-position: 0 0;
}

.tile:hover,
.tile.alt {
  --_l: var(--d);
  --_r: var(--d);
  --_i: -1;
  transition-delay: 0.1s, 0s, 0.15s, 0s;
}

/* we do a different animation for firefox since it doesn't support @property */
@supports not (-webkit-mask: paint(p)) {
  .tile,
  .tile.alt {
    transition: 0.3s;
  }

  .tile:hover,
  .tile.alt:hover {
    --_i: 0;
    --_l: var(--d);
    --_r: 0px;
  }
}
