@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.w-full {
  width: 100%;
}

.image-gallery-slide-wrapper {
  border-radius: 20px;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.image-gallery-bullets .image-gallery-bullet:hover,
.image-gallery-bullets .image-gallery-bullet.active:hover {
  background: #7ac824;
  border: 1px solid #7ac824;
}

.image-gallery-fullscreen-swipe {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery-image {
  height: 100%;
}

.image-gallery-content.fullscreen,
.image-gallery-content.fullscreen .image-gallery-slide-wrapper {
  height: 100%;
}
